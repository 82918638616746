import React from "react";

const config = {
  ROMS: {
    NesTest: {
      name: "LD44",
      description: "LD44 - The life of Cirkat",
      url: "http://cloud.weirdfox.com/GameJam/LD44.nes"
    }
  }
};

export default config;